import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FormattedDate } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

const PostTemplate = ({ data, pageContext }) => {
    const { title, date } = data.markdownRemark.frontmatter
    const { prev, next } = pageContext
    const __html = data.markdownRemark.html

    return (
        <Layout page='blog' titles={['BLOG', 'POST']}>
            <SEO title="Elevage" keywords={[`Elevage`, `Cedrenchante`, `blog`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]} />
            <div className="container">

                <FormattedDate value={date} year="numeric" month="long" day="2-digit"/>

                <h1>{title}</h1>

                {data.markdownRemark.photo_principale &&
                <Img fluid={data.markdownRemark.photo_principale.childImageSharp.fluid} style={{ width: "100%", maxWidth: "400px", margin: "auto" }} alt="Logo Le Cèdre Enchanté"/>}

                <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html }} />
                <Divider />
                {prev && <p><Link to={`/${prev.frontmatter.slug}`}><ArrowLeftOutlined /> {prev.frontmatter.title}</Link></p>}
                {next && <p><Link to={`/${next.frontmatter.slug}`}><ArrowRightOutlined /> {next.frontmatter.title}</Link></p>}

            </div>
        </Layout>
    )
}

export default PostTemplate




// export default ({ data, pageContext }) => {
//     const { title, date, slug, photo_principale } = data.markdownRemark.frontmatter
//     const __html = data.markdownRemark.html
//     const { prev, next, page } = pageContext
//     let path = 'page/' + page 
//     if(page === 1)
//         path = ''
//     const returnAnchor = path + '#'.concat({slug}.slug)
//     return (
//         <div>
//             <div style={{width: '100%', height: '100px', textAlign:"right"}}>
//                 <Link to={`/${returnAnchor}`}><Icon style={{ fontSize: '50px', fontWeight: '100'}} type="close" /></Link>
//             </div>
//             <div style={{ margin:'0 20vw' }}>
//                 <SEO title={title} description={data.markdownRemark.excerpt} keywords={[`Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]}/>
//                 <h1 style={{ textAlign:'center'}}>{title}</h1>
//                 <DateFormating date={date}/>
//                 <div>
//                     {photo_principale && 
//                      <Image
//                      resolutions="small"
//                      src={photo_principale}
//                      alt={"Elevage du Cedre Enchante"}
//                      />}
//                 </div>
//                 <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html }} />
//                 <Row type='flex' gutter={18} style={{textAlign: "center", margin: '30px 0'}}>
//                     <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ margin: '5px 0'}}>
//                     {prev && prev.frontmatter.title && (
//                         <Link to={`/${prev.frontmatter.slug}`}><Icon type='left'/> {prev.frontmatter.title}</Link>
//                     )}
//                     </Col>
//                     <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ margin: '5px 0'}}>
//                     <Link to='/' style={{ textAlign: 'center'}}>Tous les articles</Link> 
//                     </Col>
//                     <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ margin: '5px 0'}}>
//                     {next && next.frontmatter.title && (
//                         <Link to={`/${next.frontmatter.slug}`}>{next.frontmatter.title} <Icon type='right'/></Link>
//                     )}
//                     </Col>
//                 </Row>
//             </div>
//         </div>
//     );
// };

export const query = graphql`
    query($slug: String!){
        markdownRemark(frontmatter: { slug: { eq: $slug } }){
            html
            excerpt
            frontmatter {
                slug
                title
                date
                photo_principale
            }
            photo_principale {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    }
`
